.ant-pagination-item-active {
  border-color: #de7c00 !important;
}

.ant-pagination-item-active a {
  color: #de7c00 !important;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #de7c00 !important;
}

.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
  border-color: #de7c00 !important;
}

.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
  color: #de7c00 !important;
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #de7c00 !important;
  color: #de7c00 !important;
}
