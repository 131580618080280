.container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.actions-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.actions-icon {
  cursor: pointer;
}

.actions-icon:hover {
  color: #de7c00;
}

.icon {
  font-size: 18px;
}
