.app {
  background-color: #ffffff;
  min-height: 100vh;
  min-width: 100vw;
  height: 100%;
  width: 100%;
}

.bold {
  font-weight: bold;
}
