.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #de7c00 !important;
  border-color: #de7c00 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #de7c00 !important;
}
