.account-radio {
  margin-top: 8px;
}

.account-info-container {
  display: flex;
  margin-top: 21px;
  margin-bottom: 28px;
}

.account-personal-inner-container {
  display: flex;
  margin-bottom: 23px;
  margin-right: 84px;
  justify-content: space-between;
  width: 18.75rem;
}
