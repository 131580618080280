.api-input-field {
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  min-height: 32px;
  padding: 6.5px 11px;
}

.api-header {
  padding-left: 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.button {
  min-width: 6rem !important;
  max-width: 30% !important;
}

.api-input-text {
  font-size: 16px !important;
}
