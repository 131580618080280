.admin-header {
  display: flex !important;
  justify-content: space-between;
  padding: 1rem;
}

.add-company {
  width: fit-content !important;
}

.remove-company {
  width: 100% !important;
}

.sign-out {
  width: fit-content !important;
  margin-left: 0.5rem;
}

.sign-out-icon {
  margin-right: 0.5rem;
}

.user-info {
  margin-bottom: 1rem !important;
}

.email-form {
  width: 20rem;
  margin-right: 5rem;
}

.password-form {
  width: 20rem;
}
