.report-waste-form-container {
  display: flex;
  width: 100%;

  flex-wrap: wrap;
}

.report-waste-form-container > * {
  margin-top: 1.5rem;
}

.report-waste-form-container :not(:last-child) {
  margin-right: 1rem;
}

.waste-type-error {
  color: #ff0000;
}

.report-waste-submit {
  margin-right: 0;
  margin-left: auto;
}

.report-waste-specification-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 18.75rem;
  height: 10rem;
  justify-content: space-between;
}

.waste-list-container {
  display: flex;
  flex-direction: column;
}

.waste-list-container-item {
  border-bottom: 1px solid #dbdbda;
  display: flex;
  height: 100%;
}

.waste-list-container-item-weight {
  width: 3.875rem;
  background-color: #f4f6f5;
  border-left: 1px solid #dbdbda;
  border-right: 1px solid #dbdbda;
  border-bottom: 2px solid #de7c00;
  mix-blend-mode: normal;
  text-align: center;
  margin-left: 1rem;
}

.waste-list-container-item-unit {
  width: 3.125rem;
  border-right: 1px solid #dbdbda;
  color: #de7c00;
}

.waste-list-container-item-weight p {
  font-weight: 400;
  font-size: 8pt;
}

.waste-list-container-item-unit p {
  font-weight: 400;
  font-size: 10pt;
}

.waste-list-container-item p {
  margin: 1rem 1rem;
  font-weight: 400;
  width: 40%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.waste-list-container-item-right {
  flex-grow: 1;
  align-self: center;
}

.waste-list-container-item-right > .small-button-container {
  float: right;
  margin-right: 1rem;
}

.add-waste {
  margin-top: 1rem;
}

/*google api widget*/
.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 1000;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.hdpi.pac-logo:after {
  display: none !important;
}

.pac-logo:after {
  display: none !important;
}

.pac-item {
  padding: 0 0.5rem;
}

.pac-item:hover {
  cursor: pointer;
}

.pac-item-selected:hover {
  cursor: pointer;
}

.hdpi .pac-icon {
  display: none;
}
