.select-company {
  margin-top: 100px;
}

.select-company .radio-container {
  display: flex;
  flex-direction: column !important;
  margin-bottom: 20px;
}

.select-company h2 {
  font-size: 14px;
  padding: 0;
  margin: 0;
  font-style: italic;
}