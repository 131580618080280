.text-field-outer-container {
  width: 100%;
}

.text-field-container {
  display: flex;
  width: 100%;
  height: 3.125rem;
  border: 1px solid #dbdbda;
  border-radius: 2px;
  padding: 0.5rem 1rem 0.5rem;
  background-color: #fff;
  box-sizing: border-box;
}

.text-field-container-icon {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  color: #de7c00;
}

.text-field-container-right {
  width: 100%;
}

.text-field-container-right > p {
  font-size: 6pt;
  line-height: 7pt;
  margin: 0;
  color: #2a2a2a;
}

.text-field-container-right > input {
  border: none;
  margin-top: 0.25rem;
  height: 1rem;
  width: 100%;
  font-size: 9pt;
  line-height: 11pt;
}

.text-field-container-right > input:focus {
  outline: none;
  border-bottom: 1px solid #de7c00;
}

.error {
  border-color: #ff0000 !important;
}

.error-message {
  color: #ff0000;
  display: flex;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  font-size: 7pt;
}
