.container {
  height: 2.5rem !important;
  padding: 0 1rem;
  background-color: #de7c00 !important;
  border: none !important;
  color: #fff !important;
  font-weight: bold !important;
  border-radius: 3px !important;
  width: 100% !important;
}

.container:hover {
  background-color: #ffac40 !important;
  color: #fff !important;
}

.bordered-container {
  background-color: transparent !important;
  border: 1px solid #de7c00 !important;
  color: #de7c00 !important;
}

.bordered-container:hover {
  border: 1px solid #ffac40 !important;
  color: #ffac40 !important;
  background-color: transparent !important;
}

.width-fit {
  width: fit-content !important;
}
