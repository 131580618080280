body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
b {
  font-family: 'Roboto';
}

h3,
p {
  margin-bottom: 0 !important;
}

h6 {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  padding: 3.25rem 5.25rem;
}

.sub-container {
  margin-bottom: 2rem;
}

.add-button {
  width: 5rem !important;
  height: 2rem !important;
  border: 1px solid #de7c00 !important;
}

.margin-bottom-1rem {
  margin-bottom: 1rem;
}

.clickable {
  cursor: pointer;
}

@media screen and (min-width: 900px) {
  .display-none-min-900 {
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .display-none-max-900 {
    display: none !important;
  }
}

.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 10px;
}

::selection {
  color: #fff !important;
  background: #de7c00 !important;
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #de7c00;
  position: sticky;
  height: 10rem;
  top: 0;
  background: #ffffff;
  z-index: 1;
}

@media screen and (max-width: 901px) {
  .banner {
    min-height: 13rem;
    max-height: 20rem;
    padding: 2rem;
  }
}
