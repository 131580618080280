.report-waste-modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.static-list-container {
  display: flex;
  flex-direction: column;
}

.static-list-container-item {
  border-bottom: 1px solid #dbdbda;
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
  height: 100%;
}

.category-list-container {
  display: flex;
  flex-direction: column;
}

.category-list-sub-container {
  display: flex;
  flex-direction: column;
}

.category-list-sub-container-title {
  border-bottom: 1px solid #dbdbda;
  font-weight: 700;
  margin: 0;
  padding: 1rem 0.5rem;
  background-color: #f4f6f5;
}
