.navbar-container {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.wrapper {
  height: 100vh;
  width: 14rem;
  min-width: 14rem;
  background: #f4f6f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: scroll;
  border-right: 1px solid #dbdbda;
}

.reduced-height {
  height: calc(100vh - 10rem) !important;
}

.nav-menu {
  display: flex;
  flex-direction: column;
}

.misc-menu {
  display: flex;
  flex-direction: column;
}

.navbar-children-container {
  overflow: scroll;
  height: 100vh;
  width: 100%;
  padding: 5rem 5rem 5rem 5rem;
}

.mobile-children-container {
  width: 100%;
  height: calc(100vh - 8rem);
  overflow: scroll;
  padding: 2rem;
}

.user-information {
  margin-top: 3rem;
  margin-bottom: 3rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.user-icon {
  display: flex;
  margin-bottom: 0.5rem;
}

.menu-text {
  margin-left: 2rem;
}

.menu-text-active {
  color: #fff !important;
}

.menu-option {
  cursor: pointer;
  height: 2.5rem;
  display: flex;
  align-items: center;
}

.menu-option:hover h6 {
  color: #de7c00;
}

.menu-option-active {
  background: #de7c00;
}

.menu-icon {
  width: 2rem !important;
}

.tiny-text {
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  margin-top: 2.5rem;
  margin-left: 2rem;
}

.logo {
  margin: 0.5rem 2rem 3.5rem;
}

.mobile-top-navbar-container {
  display: flex;
  align-items: center;
  height: 4rem;
  background-color: #f4f6f5;
  border-bottom: 1px solid #dbdbda;
  justify-content: flex-end;
  padding-right: 1rem;
}

.mobile-bottom-navbar-item {
  display: flex;
  flex-direction: column;
  width: 33%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 4rem;
}

.mobile-bottom-navbar-item:hover {
  color: #de7c00;
}

.mobile-bottom-navbar-item-active {
  color: #de7c00;
}

.mobile-top-navbar-item {
  display: flex;
  flex-direction: column;
  width: 10%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 4rem;
}

.mobile-top-navbar-item:hover {
  color: #de7c00;
}

.mobile-top-navbar-item-active {
  color: #de7c00;
}

.footer {
  background: #de7c00;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.mobile-bottom-navbar-container {
  display: flex;
  align-items: center;
  height: 4rem;
  background-color: #f4f6f5;
  border-top: 1px solid #dbdbda;
}

.toggle-test-container {
  margin-top: 1rem;
  display: flex;
  height: 2.5rem;
  justify-content: space-around;
}
