.alert-body {
  overflow: scroll;
}

.content-header-container {
  display: flex;
  flex-direction: row;
}

.fa-icon {
  font-size: 24px;
  margin-right: 1rem;
}
