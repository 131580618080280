 .login-page {
  text-align: center;
  /* display: flex;
  align-items: center;
  flex-direction: column;*/
  min-height: 100vh;
  width: 100vw;
  padding: 1rem;
} 

.login-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-header-container {
  display: flex;
  width: 100%;
  margin-top: 2rem;
}

.login-header {
  margin-top: 2rem;
}

@media screen and (max-width: 900px) {
  .login {
    /* padding: 2rem; */
  }
}

.error-text {
  color: #ff0000;
}

.login-form-container {
  width: 100%;
}

.success-text {
  color: green;
}

.badge-wrapper {
  margin-top: 20px;
}

a.forgot-password {
  margin-bottom: 20px;
  display: block;
}

.sva-login-button {
  width: 100%;
  margin-top: 20px;
}