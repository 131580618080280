.templates-modal-form > div {
  margin: 1rem 0rem;
}

.templates-modal-form > div:first-of-type {
  margin: 0;
}

.templates-modal-form-radio {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem !important;
}

.templates-modal-form-radio > b {
  margin: 1rem 0rem;
}

.waste-types {
  display: flex;
  flex-direction: column;
}

.waste-type {
  margin-top: 1rem !important;
}

.waste-type-bottom {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
