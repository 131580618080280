.big-button-container {
  border: 1px solid #de7c00;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.125rem;
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
}

.filled {
  background: #de7c00;
  color: #ffffff;
}
