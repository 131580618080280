.ant-switch-checked {
  background-color: #de7c00 !important;
}

.signature-container {
  width: 100%;
  border: 1px solid darkgrey;
  margin-bottom: 20px;
  overflow: hidden;
}

.signature-text {
  font-family: cursive;
  font-size: 16px;
  font-weight: bold;
}