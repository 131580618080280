.report-section .divider {
    width: 100%;
    border: 2px solid #de7c00;
    background: #de7c00;
  }

  .report-section .section-container {
    display: flex;
    flex-direction: column;
  }

  .report-section .section-container > h3,
  h1 {
    margin: 1rem 0;
  }

  .report-section .section-container .static-list-container-item {
    border-bottom: 1px solid #dbdbda;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .report-section .section-container .static-list-container-item p:first-child {
    float: left;
  }

  .report-section .section-container .static-list-container-item p:nth-child(2) {
    float: right;
  }

  .report-section .section-container .category-list-container {
    display: flex;
    flex-direction: column;
  }

  .report-section .section-container .category-list-sub-container {
    display: flex;
    flex-direction: column;
  }

  .report-section .section-container .category-list-sub-container-title {
    border-bottom: 1px solid #dbdbda;
    font-weight: 700;
    margin: 0;
    padding: 1rem 0.5rem;
    background-color: #f4f6f5;
  }

  .report-section .section-container .category-list-sub-container div > p {
    padding: 0.5rem 2rem;
    margin: 0;
    font-weight: 400 !important;
  }

  .report-section .section-container .hazardous-waste {
    margin-top: 180px;
  }

  .report-section .signature-heading {
    margin-top: 20px;
    padding-bottom: 20px;
    font-size: 14px;
  }

  .report-section .section-container h1 {
    font-size: 14px;
  }

  .report-section .section-container h3 {
    font-size: 11px;
  }

  .report-section .section-container p {
    font-size: 7px;
  }

  .report-section .column {
    float: left;
    width: 33.33%;
  }

  .report-section .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .report-section .report-line {
    border-bottom: 1px solid #000;
    width: 90%;
  }

  .report-section p.report-signature-label {
    margin-bottom: 10px;
    margin-top: 2px;
    font-size: 10px;
  }

  .report-section p.report-signature-content {
    margin-bottom: 2px;
    height: 20px;
  }

  .report-section p.report-signature {
    font-family: cursive;
    font-size: 12px;
    height: 20px;
    font-weight: bold;
  }

  .report-action-buttons {
    margin: 80px 0 40px 0;
  }

  .report-download-pdf-icon {
    margin-right: 5px;
    font-size: 14px;
  }

  .report-signature-image {
    height: 20px;
  }