.login {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  padding: 5rem;
}

.login-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-header-container {
  display: flex;
  width: 100%;
  margin-top: 2rem;
}

.login-header {
  margin-top: 2rem;
}

@media screen and (max-width: 900px) {
  .login {
    padding: 2rem;
  }
}

.error-text {
  color: #ff0000;
}
