.radio-container {
  display: flex;
}

.radio-container > .radio {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}

.radio-container > .radio p {
  margin: 0 0 0 0.25rem;
}

.radio-container > .radio:last-child {
  margin-right: 0;
}

.radio:hover {
  cursor: pointer;
}

.radio > .radio-button {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  border: 1px solid #dbdbda;
}

.radio > .active {
  background-color: #de7c00;
}
