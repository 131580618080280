.container {
  width: 100%;
  background-color: #de7c004f;
  border: 1px solid #de7c00;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 2rem;
}

.header {
  color: #de7c00;
}

.information {
  color: #de7c00;
}

.icon {
  margin-right: 1rem;
}
