.small-list-row {
  border-bottom: 1px solid #dbdbda;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.small-list-container {
  display: flex;
  flex-direction: column;
}

.small-list-count {
  text-align: end;
}

.small-list-footer {
  margin-top: 0.5rem;
}

@media screen and (max-width: 900px) {
  .small-list-display-none {
    display: none !important;
  }
}

.small-list-actions-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.small-list-actions-icon {
  cursor: pointer;
}

.small-list-actions-icon:hover {
  color: #de7c00;
}

.small-list-icon {
  font-size: 18px;
  margin-left: 1rem;
}
