.signature-buttons {
  display: flex;
  justify-content: space-between;
}

.signature-close {
  display: flex;
  justify-content: end;
  margin-right: 10px;
}

.signature-wrapper {
  display: flex;
}

.signature-wrapper .collapse-arrow {
  margin: 0 10px 0 0;
}

.signature-modal, .camera-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1;
  text-align: center;
}

.signature-modal::backdrop {
  background-color: pink;
}

.signature-content {
  position: absolute;
  top: 50%;
  width: 90vw;
  left: 50%;
  transform: translate(-50%, -50%);
}

.signature-content p {
  margin-bottom: 10x;
}
.signature-image {
  margin-bottom: 5px; 
  max-width: 100% 
}

.camera-thumb {
  width: 400px;
}

.camera-buttons {
  margin-top: 20px;
}

.camera-buttons button {
  margin-right: 20px;
  width: auto !important; 
}