.ant-select {
  width: 100%;
  height: 40px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px !important;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 40px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #de7c00 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: 0 0 0 2px rgb(250 173 20 / 20%) !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #de7c00 !important;
  box-shadow: 0 0 0 2px rgb(250 173 20 / 20%) !important;
}

.error-message {
  color: #ff0000;
  display: flex;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  font-size: 7pt;
}
