.ant-input:hover {
  border-color: #de7c00 !important;
}

.ant-input:focus {
  border-color: #de7c00 !important;
  box-shadow: 0 0 0 2px rgb(250 173 20 / 20%) !important;
}

.ant-input-focused {
  border-color: #de7c00 !important;
}

.ant-input-affix-wrapper-focused {
  border-color: #de7c00 !important;
  box-shadow: 0 0 0 2px rgb(250 173 20 / 20%) !important;
}

.ant-picker-focused {
  border-color: #de7c00 !important;
  box-shadow: 0 0 0 2px rgb(250 173 20 / 20%) !important;
}

.ant-picker:hover {
  border-color: #de7c00 !important;
}

.ant-btn-primary {
  background-color: #de7c00 !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #de7c00 !important;
}
