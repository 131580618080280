:root {
    --progress-width: 24px;
  }

.report-states {
  padding: 0;
  list-style-type: none;
  font-size: 14px;
  font-weight: 700;
  clear: both;
  display: inline-block;  
  line-height: 1em;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    margin: 0 0 0 20px;
  }
}

.report-states div {
  display: flex;
  align-items: center;
  float: left;
  height: 50px;
  padding: 10px 10px 10px 40px;
  background: #ccc;
  color: #888;
  position: relative;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  width: 200px;
  margin: 0 1px;
}

/* .report-states li:first-child {
  padding-left: 20px;
  color: white;
} */

/* .report-states li:not(:first-child):before { */
.report-states :before {
  content: "";
  border-left: var(--progress-width) solid #fff;
  border-top: var(--progress-width) solid transparent;
  border-bottom: var(--progress-width) solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.report-states div:after {
  content: "";
  border-left: var(--progress-width) solid #ccc;
  border-top: var(--progress-width) solid transparent;
  border-bottom: var(--progress-width) solid transparent;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 20;
}

.report-states div.done {
  background: #f86b32;
  color: white;
}

.report-states div.active:after {
  border-left-color: #d5461d;
}

.report-states div.done:after {
  border-left-color: #f86b32;
}
  