.section-container {
  margin-bottom: 3rem;
}

.section-inner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.section-inner-container span {
  font-size: 12px;
}
