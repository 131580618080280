.reporting-history-actions-container {
  display: flex;
  align-items: center;
}

.reporting-history-actions-icon {
  cursor: pointer;
}

.reporting-history-actions-icon:hover {
  color: #de7c00;
}

.pdf-container {
  color: #de7c00;
  display: flex;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pdf-icon {
  font-size: 18px;
  margin-left: 1rem;
}
